class ZIDXAccountDefaultSearchSettings implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountDefaultSettingSettingsContainer";
    }
    getMatchURL(){
        return "/account/settings/default-search-settings";
    }
    render(){
        // console.log("default search settings");
    }
}